import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignKin = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Kin</title>
      <meta name="Service Design - Kin" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.kincover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">SERVICE DESIGN</span>
            <span className="tag">UX STRATEGY</span>
            <span className="tag">USER EXPERIENCE</span>
            <h1>kin.</h1>
            <h3>
              KIN enables trusted relationships through empathic encounters
              between a newly diagnosed person and someone who has been living
              with Type II Diabetes for a number of years, to provoke positive
              lifestyle changes that delay or reverse the development of their
              symptoms.
            </h3>
          </header>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  We were tasked with the challenge of designing a
                  human-centered and conversation-based services for the
                  management of type II diabetes. Despite vast amounts of
                  information and targeted programmes, Type II Diabetes
                  continues to rise around the world.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2016
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 5 days
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Design of a conversational
                  based service using the Design Sprint approach developed by
                  Google Ventures.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Idoia Teran, Elisa Mombrini,
                  Laila Simonovsky, Monsicha Plukpitakkul & Gina Garvey.
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Primary & Secondary Research,
                  Testing of Assumptions with users, Analysis of Research &
                  feedback, Ideation & Concept Development, Service Blueprinting
                  & Presentation.
                </span>
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.kinchallenge.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              There is an information overload regarding Diabetes when people
              move from prediabetes to Type II Diabetes. Most existing resources
              (programs/info) are not personalized and are "one size fits all"
              (gender/ethnicity/etc) and this approach is not working.
            </p>
            <p className="full-text">
              We were thus required to look into the needs, expectations and
              experiences of pre-diagnosed patients throughout diagnosis and
              management of their diabetic lifestyles and develop the required
              interventions based on our research.
            </p>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <p className="full-text">
              A 5-day workshop that explored different tools, techniques,
              iterative and hands-on approaches to allow envisioning,
              future-proofing and testing of different concepts. All the
              activities helped to quickly evaluate assumptions and reach the
              end of the week with a solid value proposition for their
              ‘conversational’ services.
            </p>
            <div className="process-image">
              <Img fluid={props.data.kin_process.childImageSharp.fluid} />
            </div>
            <h4>DAY 01 </h4>
            <div className="full-text">
              <p>
                Desk research and coming up with assumptions. We began with
                carrying out secondary research - Lifestyle, Awareness, Access
                and Management - formed our four pillars of research. As i was
                in charge of the lifestyle pillar, I thoroughly investigated the
                role of lifestyle as a factor of both development and treatment
                of type II Diabetes.
              </p>
              <p>
                Overall, we found that people experience feelings of fear,
                disbelief and paralysis when diagnosed and can subsequently feel
                judged and ‘preached to’ which lessens their desire to make the
                necessary lifestyle changes to improve their health.
              </p>
              <p>
                This led to our main assumption: Enabling a face to face trusted
                relationship between a newly diagnosed person and someone who
                has been living with Type II Diabetes for a number of years,
                would provoke positive lifestyle changes to delay or reverse the
                development of their symptoms in a measurable way. Next, we had
                to test this assumption.
              </p>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kinprocess2.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kinprocess3.childImageSharp.fluid} />
              </div>
            </div>
            <p className="full-text">
              We prepared for testing the assumptions and initial
              proposals/ideas in the following day by coming up with a set of
              questionnaire. A one-page website on the platform Launchpad was
              set up; it is a tool that was used to convey the initial idea of
              the service to the users in order to obtain their first
              impressions of the service.
            </p>
            <div className="image">
              <Img fluid={props.data.kinprocess4.childImageSharp.fluid} />
            </div>
            <h4>DAY 02 </h4>
            <div className="full-text">
              <p>
                Meeting with people and gathering of feedback on the
                assumptions.
              </p>
            </div>
            <div className="image">
              <Img fluid={props.data.kinprocess5.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Patient Groups were visited at a hospital and homes, email were
              sent to various diabetes organisations. Plus questionnaires were
              sent through Typeform tool to various patients and 1-1 Skype
              interviews were carried out.
            </p>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.kinprocess6.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess7.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess8.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess9.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess10.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess11.childImageSharp.fluid} />
              </Slider>
            </div>
            <h4>DAY 03 </h4>
            <div className="full-text">
              <p>
                Feedbacks were clustered and findings were analysed to see what
                worked and what didn't, what can be changed and what new
                assumptions could be made. The insights were used to perform two
                major tasks - build user personas and develop user journey maps.
                Being a curator of the user journeys, I learnt the importance of
                tailoring the maps and finding the right ways of visualizing
                them for different personas. Early ideas were tweaked and
                further developed for final narrative.
              </p>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.kinprocess12.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.kinprocess13.childImageSharp.fluid} />
              </div>
            </div>
            <div className="image">
              <Img fluid={props.data.kinprocess14.childImageSharp.fluid} />
            </div>
            <h4>DAY 04 </h4>
            <div className="full-text">
              <p>
                The final concept was consolidated by making a service blueprint
                of their lifestyle, awareness, access and management phases.
                Finally a Business model was mapped out so as to identify the
                economic viability of the proposed service.
              </p>
            </div>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.kinprocess15.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess16.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess17.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess18.childImageSharp.fluid} />
              </Slider>
            </div>
            <h4>DAY 05 </h4>
            <div className="full-text">
              <p>
                Presentation of the service through a one page website that
                captured the concept, hypothesis and overall idea.
              </p>
            </div>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.kinprocess19.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess20.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess21.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess22.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess23.childImageSharp.fluid} />
                <Img fluid={props.data.kinprocess24.childImageSharp.fluid} />
              </Slider>
            </div>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <p className="full-text">
                Kin is a service that focuses on improving the management of
                (type II) diabetes.Through the Kin buddy system, we match people
                in the physical world who have recently been diagnosed with Type
                II Diabetes with trained volunteers who also have the condition,
                by introducing them at Kin organised events based on their
                mutual interests and passions. This allows the matched people to
                discuss about their common interests and use that as a starting
                point to talk about their Diabetes and how they can better
                manage it.
              </p>
              <h3>
                Overall, Kin alleviates the shock patients feel after diagnosis
                to enable them to more quickly understand, accept and begin
                making positive changes through ongoing personal and empathetic
                support.
              </h3>
            </div>
            <div className="image">
              <Img fluid={props.data.kinoutcome25.childImageSharp.fluid} />
            </div>
          </section>
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to check out my
              <br />
              <Link to="/#one">featured work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-mercedes">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">electric car charging service.</div>
                <div className="text">
                  A seamless parking & charging solution for Mercedes Benz &
                  Smart Electric Car owners living in urban areas.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-muvisi-private">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">muvisi</div>
                <div className="text">
                  A mobility service that makes it stress-free & convenient to
                  find parking spots in the city.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>DIGITAL UX/UI</li>
                    <li>INTERACTION DESIGN</li>
                    <li>BRANDING</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignKin

export const fluidKinImage = graphql`
  fragment fluidKinImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    kincover: file(
      relativePath: { eq: "projects/service/kin/cover/kin-cover-image.jpg" }
    ) {
      ...fluidKinImage
    }
    kinchallenge: file(
      relativePath: { eq: "projects/service/kin/challenge/1.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess2: file(
      relativePath: { eq: "projects/service/kin/process/2.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess3: file(
      relativePath: { eq: "projects/service/kin/process/3.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess4: file(
      relativePath: { eq: "projects/service/kin/process/4.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess5: file(
      relativePath: { eq: "projects/service/kin/process/5.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess6: file(
      relativePath: { eq: "projects/service/kin/process/6.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess7: file(
      relativePath: { eq: "projects/service/kin/process/7.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess8: file(
      relativePath: { eq: "projects/service/kin/process/8.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess9: file(
      relativePath: { eq: "projects/service/kin/process/9.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess10: file(
      relativePath: { eq: "projects/service/kin/process/10.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess11: file(
      relativePath: { eq: "projects/service/kin/process/11.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess12: file(
      relativePath: { eq: "projects/service/kin/process/12.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess13: file(
      relativePath: { eq: "projects/service/kin/process/13.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess14: file(
      relativePath: { eq: "projects/service/kin/process/14.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess15: file(
      relativePath: { eq: "projects/service/kin/process/15.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess16: file(
      relativePath: { eq: "projects/service/kin/process/16.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess17: file(
      relativePath: { eq: "projects/service/kin/process/17.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess18: file(
      relativePath: { eq: "projects/service/kin/process/18.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess19: file(
      relativePath: { eq: "projects/service/kin/process/19.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess20: file(
      relativePath: { eq: "projects/service/kin/process/20.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess21: file(
      relativePath: { eq: "projects/service/kin/process/21.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess22: file(
      relativePath: { eq: "projects/service/kin/process/22.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess23: file(
      relativePath: { eq: "projects/service/kin/process/23.jpg" }
    ) {
      ...fluidKinImage
    }
    kinprocess24: file(
      relativePath: { eq: "projects/service/kin/process/24.jpg" }
    ) {
      ...fluidKinImage
    }
    kinoutcome25: file(
      relativePath: { eq: "projects/service/kin/outcome/25.jpg" }
    ) {
      ...fluidKinImage
    }
    kin_process: file(
      relativePath: { eq: "projects/process/kin-process.png" }
    ) {
      ...fluidKinImage
    }
    project1: file(relativePath: { eq: "projects/3.png" }) {
      ...fluidKinImage
    }
    project2: file(relativePath: { eq: "projects/19.png" }) {
      ...fluidKinImage
    }
  }
`
